<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex justify-between">
      <h4 class="capitalize">
        Details
      </h4>
      <div class="flex items-center">
        <vs-button
          class=""
          icon="close"
          size="medium"
          @click="$emit('remove', index)"
        ></vs-button>
      </div>
    </div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('partner.header') && partner.label != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('partner.header') ? true : false"
            v-validate="'required'"
            name="header"
            data-vv-as="header"
            label="Header"
            class="w-full"
            data-vv-scope="partner"
            :danger-text="errors.first('partner.header')"
            v-model="partner.label"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('partner.slug') && partner.value != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('partner.slug') ? true : false"
            v-validate="'required'"
            name="slug"
            data-vv-as="slug"
            label="Slug"
            class="w-full"
            data-vv-scope="partner"
            :danger-text="errors.first('partner.slug')"
            v-model="partner.value"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="description"
            ref="refDescription">
            <vs-textarea
              name="description"
              label="Description"
              class="w-full"
              v-model="partner.description"
            />
            <span class="text-red-600" v-show="errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <label class="vs-input--label">Learn More</label>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="learn more"
            ref="refLearnMore">
            <vue-ckeditor v-model="partner.learnMore" :config="config" class="mb-2"/>
            <span class="text-red-600" v-show="errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
          
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <label class="vs-input--label">Benefits</label>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="benefits"
            ref="refBenefits">
            <vue-ckeditor v-model="partner.benefits" :config="config" class="mb-2"/>
            <span class="text-red-600" v-show="errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="12"
        class="vs-con-loading__container flex flex-col">
        <div class="flex flex-col gap-3 items-start justify-between mx-5 w-full h-full">
          <label class="text-base font-semibold">
            Icon 200x200 - <span class="text-xs">Display in topic page</span>
            <span v-if="imageErrors[0].icon" class="text-red-600">
              *Icon is required
            </span>
          </label>
          <div v-if="partner.icon" class="flex flex-col items-start gap-2">
            <img :src="partner.icon" alt="Uploaded" />
            <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage('icon')">Remove Image</vs-button>
          </div>
          <div v-else id="icon" class="customHeight w-3/4 sm:w-1/2 text-center relative">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
                h-full w-full absolute flex flex-col gap-3 
                items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event, 'icon')"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputIcon" hidden @change="handleFileChange($event, 'icon')" accept="image/*" />
              <vs-button @click="clickUploadImage('fileInputIcon')" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">200 x 200</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3 items-start justify-between m-5 w-full h-full">
          <label class="text-base font-semibold">
            Banner 1205x464 - <span class="text-xs">Display in partner page</span>
            <span v-if="imageErrors[0].banner" class="text-red-600">
              *Banner is required
            </span>
          </label>
          <div v-if="partner.image" class="flex flex-col items-start gap-2">
            <img :src="partner.image" alt="Uploaded" />
            <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage('banner')">Remove Image</vs-button>
          </div>
          <div v-else id="banner" class="customHeight w-3/4 sm:w-1/2 text-center relative">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
                h-full w-full absolute flex flex-col gap-3 
                items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event, 'banner')"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputBanner" hidden @change="handleFileChange($event, 'banner')" accept="image/*" />
              <vs-button @click="clickUploadImage('fileInputBanner')" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">1205 x 464</p>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-divider />
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <div class="w-full m-5">
        <vs-card>
          <div slot="header" class="w-full items-center flex justify-between">
            <h4 class="capitalize">
              Contacts
            </h4>
            <div class="flex items-center">
              <vs-button class="mr-2" size="medium" @click="addContacts"
                >Add</vs-button
              >
            </div>
          </div>
          <div
            v-for="(contact, contactIndex) in topics[topicIndex].page.partners[index].contacts"
            :key="contactIndex"
          >
            <Contact
              :topics="topics"
              :index="contactIndex"
              :topic-index="topicIndex"
              :partner-index="index"
              :contact="contact"
              @remove="removeContact"
              ref="refContact"
            />
          </div>
        </vs-card>
      </div>
    </vs-row>
  </vs-card>
</template>

<script>
import axios from "@/axios";
import VueCkeditor from 'vue-ckeditor2';
import Contact from "./Contact.vue";
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    VueCkeditor,
    Contact,
    ValidationProvider
  },
  props: ["topics", "index", "topicIndex", "partner"],
  data() {
    return {
      imageType: "",
      imageErrors: [
        {
          icon: '',
          banner: '',
        }
      ],
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo']
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Strike',
            ]
          },
          {
            name: 'pagragraph',
            items: [
              'NumberedList',
              'BulletedList',
            ]
          },
        ],
        height: 300,
        versionCheck: false,
      },
    }
  },
  methods: {
    handleRemoveImage(type) {
      if (type === 'icon') {
        this.topics[this.topicIndex].page.partners[this.index].icon = ""
      } else if (type === 'banner') {
        this.topics[this.topicIndex].page.partners[this.index].image = ""
      }
    },
    handleDrop(event, type) {
      event.preventDefault();
      this.imageType = type;
      this.uploadImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event, type) {
      this.imageType = type;
      this.uploadImage(event.target.files[0]);
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + this.imageType,
        scale: 0.6,
      });
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (this.imageType === "icon") {
            this.topics[this.topicIndex].page.partners[this.index].icon = res.data.Location;
          } else if (this.imageType === "banner") {
            this.topics[this.topicIndex].page.partners[this.index].image = res.data.Location;
          }
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    clickUploadImage(refName) {
      this.$refs[refName].click();
    },
    addContacts() {
      this.topics[this.topicIndex].page.partners[this.index].contacts.push({
        icon: "",
        name: "",
        phone: "",
        email: "",
        clinic: "",
      })
    },
    removeContact(index) {
      this.topics[this.topicIndex].page.partners[this.index].contacts.splice(index, 1)
    },
    async validateContactForm() {
      if (this.$refs.refContact) {
        return Promise.all(
          this.$refs.refContact.map(async component => {
            if (component.handleFormValidation) {
              return await component.handleFormValidation();
            }
            return false
          })
        )
      }
    },
    async handleFormValidation() {
      let results = []
      const isValid = await this.$validator.validateAll("partner");
      const benefits = this.$refs.refBenefits
      const learnMore = this.$refs.refLearnMore
      const description = this.$refs.refDescription
      const contactsForm = await this.validateContactForm()

      // form checker
      results.push(isValid)

      // banner image checker
      if (!this.topics[this.topicIndex].page.partners[this.index].image) {
        this.imageErrors[0].banner = "error"
        results.push(false)
      }

      // icon checker
      if (!this.topics[this.topicIndex].page.partners[this.index].icon) {
        this.imageErrors[0].icon = "error"
        results.push(false)
      }

      // description checker
      if (description) {
        const { valid } = await description.validate()
        results.push(valid)
      }

      // benefits ckeditor checker
      if (benefits) {
        const { valid } = await benefits.validate()
        results.push(valid)
      }

      // learn more ckeditor checker
      if (learnMore) {
        const { valid } = await learnMore.validate()
        results.push(valid)
      }

      // contacts form checker
      if (contactsForm) {
        results = [
          ...results,
          ...contactsForm
        ]
      }

      return !results.some(bool => !bool)
    },
  },
  created() {},
}
</script>

<style lang="scss">
.customHeight {
  height: 55dvh;
}
</style>